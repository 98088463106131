.SliderBanner {
  width: 100%;
  max-height: 632px;

  .slider-banner-wrap {
    position: relative;
    width: 100%;

    .slider-banner-icon {
      color: #fff;
      transition: all 0.2s ease-out;
      appearance: none;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      outline: 0;
      position: absolute;
      z-index: 4;
      filter: drop-shadow(0 2px 2px #1a1a1a);

      svg {
        height: 60px;
        width: 30px;

        &:hover {
          color: #00abeb;
        }
      }
    }

    .slider-banner-left-arrow {
      padding: 50px 10px;
      top: 50%;
      transform: translateY(-50%);
      left: 15%;
    }

    .slider-banner-right-arrow {
      padding: 50px 10px;
      top: 50%;
      transform: translateY(-50%);
      right: 15%;
    }

    .slider-banner-bullets {
      bottom: 20px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      width: 80%;
      z-index: 4;

      .slider-banner-bullet-wrap {
        margin: 0;
        padding: 0;
        text-align: center;

        button {
          width: 15px;
          height: 15px;
          border-radius: 100%;
          appearance: none;
          background-color: transparent;
          border: 1px solid #fff;
          border-radius: 50%;
          cursor: pointer;
          display: inline-block;
          margin: 0 5px;
          outline: 0;
          padding: 5px;
          transition: background 0.2s ease-out;

          &:hover {
            background-color: #00abeb;
          }
        }

        .active {
          background-color: #00abeb;
          border: 1px solid #00abeb;
        }
      }
    }
  }
}


.mid_txt {
  color: #fff;
  line-height: 130%;
  font-size: 22px;
  margin: 50px 0;
  font-weight: bold;

  span {
    color: #00aaea
  }
}

.btm_txt {
  color: #fff;
  line-height: 150%;
  font-size: 16px;
  span { color : #00aaea }
}
