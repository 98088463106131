.BannerItem {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  img {
    max-height: 632px;
    width: 100%;
  }

  .slider-banner-content {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.center {
  position: relative;
}
