.NavigatorBoxList {
  width: 100%;
  padding : 10px;
  background-color: #e6e6e6;

  .innerWrap {
    width: 1024px;
    margin: 0 auto;
    overflow: hidden;

    .inner {
      position : relative;
      float: left;
      width: 33.33%;
      overflow: hidden;
      padding: 10px;
    }
    a { position : absolute; top : 0; left : 0; width : 100%; height : 100%; z-index : 2 }

    dl {
      display: table;
      width: 100%;
      table-layout: fixed;
      background-color: #fff;
      border: 1px solid #ccc;
    }

    dt,
    dd {
      display: table-cell;
      vertical-align: middle;
      padding: 10px 5px;
      font-size : 12px;
      letter-spacing : -0.5px;
      h2 { font-size : 15px; margin : 6px 0 0 0}
    }
    dt { padding-left : 15px }

    dd {
      width: 70px;
      height: 80px;
      text-align: center;
    }
  }
}
