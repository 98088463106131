.productTab {
    li {
        width: 25%
    }
}

.productWrap {
    >div {
        border: 1px solid #ccc;
        padding: 0 20px;
        width: 1000px;
        margin: 20px auto;
        font-size: 14px;

        h1 {
            position: relative;
            font-size: 16px;
            padding: 10px 0;
            margin: 15px 0;
            border-bottom: 1px solid #00abeb;

            span {
                position: absolute;
                top: 0;
                right: 0
            }
        }

        dl {
            display: table;
            width: 100%;
            table-layout: fixed;

            dt {
                text-align: center;
            }

            dt,
            dd {
                display: table-cell;
                vertical-align: middle;
                padding: 20px;
                line-height: 180%;
                word-break: keep-all;
            }

            h2 {
                font-size: 14px;
                margin: 0 0 20px 0
            }
        }

        >div {
            padding: 10px 0;
            overflow: hidden;

            >div {
                float: left;

                h2 {
                    font-size: 14px;
                    margin: 10px 0;

                    &+p {
                        margin: 0 !important
                    }
                }

                p {
                    margin: 35px 0 0 0
                }

                ul {
                    overflow: hidden;

                    li {
                        position: relative;
                        padding: 0 0 0 10px;
                        font-size: 15px;
                        margin: 10px 0;
                        word-break: keep-all;

                        &:before {
                            content: '';
                            width: 2px;
                            height: 2px;
                            background-color: #000;
                            border-radius: 100%;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            margin-top: -1px
                        }
                    }

                    &.twoLine {
                        li {
                            float: left;
                            width: 50%;
                            margin: 5px 0
                        }
                    }

                    &.threeLine {
                        li {
                            float: left;
                            width: 33.33%;
                            margin: 5px 0
                        }
                    }

                    &.fourLine {
                        li {
                            float: left;
                            width: 25%;
                            margin: 5px 0
                        }
                    }
                }
            }
        }
    }
}