.tech-list {
  width : 1024px; margin : 10px auto; overflow : hidden;
  .tech-list-wrap {
    .tech-item {
      float : left;
      width: 33.33%;
      position: relative;
      cursor: pointer;

      a {
        display : block;
        text-decoration: none;
        color: inherit;
        padding : 10px;
        .Wrap { border : 1px solid #ccc; height : 420px; }

        .tech-thumbnail-wrap {
          .tech-thumbnail {
            width: 100%;
            padding-top: 80%;
            background-size: contain;
            background-position: 50%;
          }
        }
        .tech-detail {
          padding: 10px 15px;
          .tech-title {
            border-bottom: 1px solid #00abeb;
            padding : 0 0 8px 0;
            font-size: 16px;
            font-weight: bold;
          }
          .tech-desc {
            margin: 1rem 0;
            font-size: 12px;
            line-height: 20px;
            p {
              line-height: 20px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
