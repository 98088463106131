.Footer {
  background-color: #f2f2f2;

  .footer-wrap {
    position: relative;
    padding: 20px 20px 20px 100px;
    width: 1024px;
    margin: 0 auto;
    font-size : 11px; color : #666;

    .footer-logo {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -15px;

      img {
        width: 72px;
        height: 35px;
      }
    }

    .footer-top-wrap {
      a { 
        display : inline-block; padding : 0 10px; 
        &:first-child { padding-left : 0 }
        &:nth-child(2) { border-right : 1px solid #ccc; border-left : 1px solid #ccc }
      }
      margin : 0 0 15px 0;
    }
    p { margin : 2px 0 }
  }
}
