@charset "utf-8";

$blue: #0b65a3;
$white: #fff;
$gray: #ccc;
$black: #222;
$red: #f03d26;

/* reset.css */
* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Malgun Gothic", "맑은고딕", "돋움", dotum, Helvetica, sans-serif;
  font-size: 12px;
  color: #333;
  line-height: normal;
  font-weight: normal;
  box-sizing: border-box;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
table,
th,
td {
  margin: 0;
  padding: 0;
  line-height: normal;
}

table,
img {
  border-collapse: collapse;
  border-spacing: 0;
}

table {
  width: 100%;
  table-layout: fixed;
}

fieldset,
img {
  border: none;
}

address,
caption,
cite,
code,
em,
th {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

abbr,
acronym {
  border: 0;
}

img,
li,
dt,
dd,
input,
select {
  vertical-align: top;
}

input,
select {
  font-family: "Malgun Gothic", "맑은고딕", "돋움", dotum, Helvetica, sans-serif;
  font-size: 12px;
}

img {
  vertical-align: middle;
}

hr {
  display: none;
}

a {
  text-decoration: none;
  color: #222;
}

a:hover {
  text-decoration: none;
}

label,
button {
  cursor: pointer;
  border: none;
  background: transparent;
}

button {
  padding: 0;
  margin: 0;
  font-family: "Malgun Gothic", "맑은고딕", "돋움", dotum, Helvetica, sans-serif;
  font-size: 12px;
}

textarea {
  font-family: "Malgun Gothic", "맑은고딕", "돋움", dotum, Helvetica, sans-serif;
  font-size: 12px;
  line-height: normal;
}

input,
button,
textarea,
select {
  border: 1px solid #ddd;
  box-sizing: border-box;
}

input[type="text"],
input[type="emai"],
input[type="tel"],
input[type="password"],
input[type="submit"],
select,
button {
  height: 35px;
  line-height: 35px;
}

/* For Print */

/*html5*/
header,
hgroup,
nav,
menu,
section,
article,
aside,
figcaption,
figure,
details,
footer {
  display: block;
}

address {
  font-style: normal;
}

/* common */
.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-top-70 {
  margin-top: 70px !important;
}

.margin-top-80 {
  margin-top: 80px !important;
}

.margin-top-90 {
  margin-top: 90px !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.relative {
  position: relative;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.text_left {
  text-align: left;
}

.font_normal {
  font-weight: normal !important;
}

.bold {
  font-weight: bold !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

.overflow {
  overflow: hidden;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}

.color_red {
  color: $red;
}

.color_blue {
  color: $blue;
}

.btn {
  background-color: transparent;
  border: 1px solid transparent;
  display: inline-block;
  transition: all 0.3s ease;
  padding: 0 15px;

  &:hover {
    background-color: $white;
  }

  & + button {
    margin: 0 10px;
  }
}

.btn_xxs {
  padding: 2px;
}

.btn_normal {
  color: #333;

  &:hover {
    color: #000;
    background-color: transparent;
  }
}

.btn_border {
  border: 1px solid $gray;
  color: #333;

  &:hover {
    background-color: #f1f1f1;
  }
}

.btn_blue {
  background-color: $blue;
  border: 1px solid $blue;
  color: #fff;

  &:hover {
    color: $blue;
  }
}

.btn_gray {
  background-color: $gray;
  border: 1px solid $gray;

  &:hover {
    color: $black;
  }
}

.table {
  display: table;
  width: 100%;
  table-layout: fixed;

  .cell {
    display: table-cell;
    vertical-align: middle;
    font-size: 12px;
  }
}

#loadingWrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);

  p {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    width: 49px;
    height: 50px;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
}

.test {
  position: relative;
  z-index: 33;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

header {
  position: relative;
  padding: 20px 0;
  z-index: 9;
  background-color: #fff;
  height: 70px;

  &:after {
    content: "";
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #eee;
    z-index: 10;
  }

  .headerInner {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 0 0 80px;

    h1 {
      position: absolute;
      top: -2px;
      left: 0;
      width: 70px;
      z-index: 2;
    }
  }

  .navWrap {
    position: absolute;
    top: -4px;
    width: 100%;
    background-color: #fff;

    > .inner {
      max-width: 1200px;
      margin: 0 auto;
      padding: 30px 0 30px 300px;
      text-align: center;

      .navlist {
        > li {
          float: left;
          width: 16.66%;
          cursor: pointer;

          &:hover {
            > a {
              color: #00abeb;
            }
          }

          > a {
            display: block;
            font-size: 15px;
            color: #333;
            font-weight: bold;
          }

          ul {
            padding: 15px 0;
            margin-top: 15px;

            li a {
              display: block;
              font-size: 14px;

              span {
                display: inline-block;
                padding: 8px;
                border-bottom: 1px solid transparent;
                transition: all 0.3s ease;
              }

              &:hover span {
                color: #00abeb;
                border-bottom: 1px solid #00abeb;
              }
            }
          }
        }
      }
    }
  }

  .subnav {
    display: none;
  }

  &:hover {
    .subnav {
      display: block;
    }
  }
}

.navTopBg {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 200px;

  > p {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    color: #fff;
    line-height: 200px;
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.imgWrap {
  padding: 30px 0;

  img {
    display: block;
    max-width: 100%;
    height: auto !important;
    margin: 0 auto;
  }
}

.tabDesign {
  position: relative;
  top: -27px;
  overflow: hidden;

  &.companyTab li {
    width: 20%;
  }

  li {
    float: left;

    a {
      display: block;
      height: 55px;
      line-height: 55px;
      text-align: center;
      background-color: #f1f1f1;
      margin: 0 10px;
      font-size: 14px;

      &.active {
        background-color: #00abeb;
        color: #fff;
      }
    }
  }
}
